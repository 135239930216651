import Vue from "vue";

/*TITLE*/
document.title = "UTE Los Tejares | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "UTE Los Tejares";
Vue.prototype.$subtitle = "Tu sueño hecho realidad";

/*INTRO*/
Vue.prototype.$promoter = "UTE Los Tejares";
Vue.prototype.$introSubtitle = "Tu sueño hecho realidad";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-ute-tejares-residence-utah-copia--20230116110111.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-1-plurifamiliares-madagascar-blanco-deco-ona-copia--20230116110122.jpg";
Vue.prototype.$image_bath2 = "banyo-2-plurifamiliares-madagascar-blanco-deco-ona-copia--20230116110139.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-ute-tejares-residence-utah-copia--20230116110111.jpg",
  },
  {
    src: "salon-ute-tejares-residence-nevada-copia--20230116110112.jpg",
  },
  {
    src: "salon-ute-tejares-residence-arizona-copia--20230116110114.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1-plurifamiliares-madagascar-blanco-deco-ona-copia--20230116110122.jpg",
  },
  {
    src: "banyo-1-plurifamiliares-bottega-caliza-deco-marbella-stone-copia--20230116110126.jpg",
  },
  {
    src: "banyo-1-plurifamiliares-bottega-acero-deco-marbella-stone-copia--20230116110131.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2-plurifamiliares-madagascar-blanco-deco-ona-copia--20230116110139.jpg",
  },
  {
    src: "banyo-2-plurifamiliares-bottega-acero-deco-marbella-stone-copia--20230116110145.jpg",
  },
  {
    src: "banyo-2-plurifamiliares-bottega-caliza-deco-marbella-stone-copia--20230116110106.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=lora%20del%20río%20sevilla%20",
    text: "Lora del Río (Sevilla)",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20sierra%20de%20cazorla%208%20lora%20del%20río%20sevilla%20",
    text: "C/Sierra de Cazorla, 8, Lora del Río (Sevilla)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:690637207",
    text: "690637207",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@utelostejares.es",
    text: "info@utelostejares.es",
  },
];
